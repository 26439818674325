<template>
  <div :class="className" @click.stop="$emit('handleDivClick')">
    {{ txt }}
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "DivWrapper",
  props: {
    className: {},
    txt: {
      type: String,
      default: "",
    },
  },
};
</script>
