<template>
  <div-wrapper className="loading">
    <image-comp imgName="loader.gif" />
  </div-wrapper>
</template>

<script>
import ImageComp from "../form/ImgWrapper";
import DivWrapper from "../form/DivWrapper";
export default {
  components: {
    ImageComp,
    DivWrapper
  }
};
</script>

<style lang="css" scoped>
.loading {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(241, 245, 248, 0.96);
  z-index: 111;
  width: 100%;
  height: 100%;
}
.loading img {
  position: absolute;
  width: 90px;
  height: 90px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
}
</style>
