export const TWO_HUNDRED = 200;
export const THREE_HUNDRED = 300;
export const FIVE_HUNDRED = 500;
export const FOUR_HUNDRED_ONE = 401;
export const TWO_HUNDRED_FOUR = 204;
export const TWO_HUNDRED_FIVE = 205;
export const GET = "get";
export const POST = "post";
export const PUT = "put";
export const DELETE = "delete";
