import axios from "axios";
import { SessionHelper } from "./Session";
import {
    TWO_HUNDRED,
    THREE_HUNDRED,
    FOUR_HUNDRED_ONE,
    FIVE_HUNDRED,
    TWO_HUNDRED_FOUR,
    TWO_HUNDRED_FIVE,
} from "@/utils/HttpStatusCodeConst";

// check http status code and throw error or exception
const checkStatus = (response) => {
    if (response.status >= TWO_HUNDRED && response.status < THREE_HUNDRED) {
        return response;
    }
    if (response.status === FIVE_HUNDRED) {
        return response.json().then((errorData) => {
            const message = (errorData || {}).error;
            const error = new TypeError(message || "Server error");
            throw error;
        });
    }
    if (response.status === FOUR_HUNDRED_ONE) {
        const error = {
            code: response.status,
            data: "Unauthorized",
        };
        throw error;
    }
    return response.json().then((errorData) => {
        const error = {
            code: response.status,
            data: errorData,
        };
        throw error;
    });
};

// parse response data
const parseJSON = (response) => {
    if (
        response.status === TWO_HUNDRED_FOUR ||
        response.status === TWO_HUNDRED_FIVE
    ) {
        return null;
    }
    const contentType = response.headers["content-type"];
    if (contentType && contentType.indexOf("application/json") !== -1) {
        return response.data;
    }
    return null;
};

// Make an api call
export default async (baseUrl, endpoint, method = "post", body) => {
    const content_type = method === 'get' ? '' : 'application/json'
    return axios(`${baseUrl}${endpoint}`, {
        method,
        data: body ? JSON.stringify(body) : null,
        headers: SessionHelper.getHeader(content_type),
    })
        .then(checkStatus)
        .then(parseJSON)
        .catch((error) => {
            console.log("error", error);
            throw error;
        });
};
