<template>
  <div class="profile-cnt">
    <loader-wrapper v-if="isLoading" />
    <header class="inner-head">
      <div class="left-col">
        <span class="icon-btn" @click="$router.go(-1)">
          <BackIcon />
        </span>
        <h1>Profile</h1>
      </div>
    </header>

    <section class="mid-cnt">
      <div class="field-row profile-img">
        <span class="profile-icon">
          <AvtarDefaultIcon />

          <span class="icon-btn uploadicon">
            <CameraIcon />
            <input type="file" class="upload-photo" />
          </span>
        </span>
      </div>

      <div class="fomr-box">
        <div class="field-row">
          <label class="text-label">Name</label>
          <input
            v-model="userInfo.name"
            name=""
            value="Akshay"
            class="input-style"
            placeholder="Name"
          />
        </div>
        <div class="field-row">
          <label class="text-label">City</label>
          <input
            v-model="userInfo.city"
            name=""
            value="Akshay"
            class="input-style"
            placeholder="City"
          />
        </div>
        <div class="field-row">
          <label class="text-label">State</label>
          <input
            v-model="userInfo.state"
            name=""
            value="Akshay"
            class="input-style"
            placeholder="State"
          />
        </div>

        <div class="field-row">
          <label class="text-label">Mobile Number</label>
          <input
            name=""
            :value="userInfo.mobileNo"
            class="input-style disable"
            placeholder="Mobile Number"
            disabled
          />
        </div>

        <!--        <div class="field-row gender-field">-->
        <!--          <label class="text-label">Gender </label>-->

        <!--          <div class="optionbox">-->
        <!--            <div class="input_radicol">-->
        <!--              <label class="input-check">-->
        <!--                <input type="radio" name="radio" />-->
        <!--                <span class="gender-label">Male</span>-->
        <!--                <span class="checkmark"></span>-->
        <!--              </label>-->
        <!--            </div>-->

        <!--            <div class="input_radicol">-->
        <!--              <label class="input-check">-->
        <!--                <input type="radio" name="radio" />-->
        <!--                <span class="gender-label">Female</span>-->
        <!--                <span class="checkmark"></span>-->
        <!--              </label>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->

        <!--        <div class="field-row">-->
        <!--          <label class="text-label">About Your Self</label>-->

        <!--          <textarea class="textare-style" placeholder="Write Here...">-->
        <!--Lorem ipsum dolor sit amet, consectetur adipising elit. Morbi id lacinia ante. Suspendisse ullcorper odio quis ultrices tempor.-->
        <!--          </textarea>-->
        <!--        </div>-->

        <div class="field-row">
          <label class="text-label">Address</label>

          <textarea
            v-model="userInfo.address"
            class="textare-style"
            placeholder="Write Here..."
          >
          </textarea>
        </div>
      </div>
    </section>

    <footer class="footer-bottom">
      <button @click="updateUserInfo" class="button">प्रोफाइल अपडेट करे</button>
    </footer>
  </div>
</template>

<script>
import BackIcon from "@/components/svgIcons/BackIcon";
import CameraIcon from "@/components/svgIcons/CameraIcon";
import { mapGetters } from "vuex";
import ApiRequest from "@/services/ApiRequest";
import { BASE_API } from "@/utils/constants";
import { POST } from "@/utils/HttpStatusCodeConst";
import LoaderWrapper from "../components/commonUI/LoaderWrapper.vue";
import AvtarDefaultIcon from "@/components/svgIcons/AvtarDefaultIcon";
export default {
  name: "UserProfile",
  components: {
    BackIcon,
    CameraIcon,
    LoaderWrapper,
    AvtarDefaultIcon,
  },
  data() {
    return {
      userInfo: null,
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({ getUserInfo: "getUserInfo" }),
  },
  created() {
    this.userInfo = this.getUserInfo;
    this.userInfo.city = this.getUserInfo.city || "";
    this.userInfo.name = this.getUserInfo.name || "";
    this.userInfo.state = this.getUserInfo.state || "";
    this.userInfo.mobileNo = this.getUserInfo.mobileNo || "";
    this.userInfo.address = this.getUserInfo.address || "";
    // console.log(this.userInfo);
  },
  methods: {
    updateUserInfo() {
      this.isLoading = true;
      ApiRequest(BASE_API, `/partner91/update`, POST, this.userInfo)
        .then((response) => {
          if (response && response.result && response.result.status) {
            this.isLoading = false;
            this.$swal({
              icon: "success",
              text: `प्रोफ़ाइल सफलतापूर्वक अपडेट हो गई है।`,
            });
          } else {
            this.isLoading = false;
            this.$swal({
              icon: "error",
              text: `कुछ गलत हुआ, कृपया कुछ समय बाद प्रयास करें।`,
            });
          }
        })
        .catch((error) => {
          console.log("error:", error);
          this.isLoading = false;
          this.$swal({
            icon: "error",
            text: `कुछ गलत हुआ, कृपया कुछ समय बाद प्रयास करें।`,
          });
        });
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/user-profile.scss";
</style>
