const mode = "prod";
// let a = "../config/" + mode + ".json";
const config = require(`../config/${mode}.json`);
module.exports = {
  BASE_API: config.BASE_API,
  FILE_UPLOAD_URL: config.FILE_UPLOAD_URL,
  LOCAL_SHOP_URL: config.LOCAL_SHOP_URL,
  FILE_UPLOAD_URL_OLD: "https://media.getzyk.com/uploadMediaFile/6",
  DISPUTE_SUB_CATEGORIES: {
    incomingOrders: 84,
    newOrders: 85,
    deliveryOrders: 86,
    returnOrders: 87,
    redispatchOrders: 88,
    lostOrders: 89,
    CODOrders: 90,
    others: 91,
  },
};
