<template>
  <img
    v-if="imgName || url"
    :src="imgName ? require(`../../assets/images/${imgName}`) : url"
    :alt="imgName || url"
    :class="className"
  />
</template>

<script>
export default {
  props: {
    className: {
      type: String,
      default: ""
    },
    imgName: {
      type: String,
      default: ""
    },
    url: {
      type: String,
      default: ""
    }
  }
};
</script>
